<template>
  <div>
    <div class="flex">
      <p class="font-barlow font-medium text-xl text-grey-medium-2">
        {{ title }} {{ displayVersionForSeller(productVersion) }} /
      </p>
      <p class="font-barlow font-bold text-xl text-grey-dark-2 ml-1">
        {{ isIndividual ? "Targets" : "Organization Targets" }}
      </p>
      <div class="flex flex-grow justify-end">
        <Button
          text="Back"
          type="primary-white"
          size="medium"
          :iconLeft="arrowLeft"
          :rotateArrow="true"
          :onClick="goBack"
          class="mr-2"
          data-testid="back-button"
        />
        <Button
          text="Next"
          type="primary"
          :iconRight="arrowRight"
          size="medium"
          :onClick="goNext"
          data-testid="next-button"
        />
      </div>
    </div>
    <div class="bg-white shadow rounded px-2 pt-3 mt-3 pb-4.5 mb-4">
      <p class="font-barlow font-bold text-mdl text-grey-light mb-2">
        Review or Update Targets
      </p>
      <div class="px-2">
        <p class="text-mdh text-grey-dark-1 mb-3 max-w-1/2">
          The targets displayed are based on the professional benchmark. If you
          want to accept the benchmark target, click next. You may adjust the
          target by competency using values between 0 and
          {{ competencyLevels != null ? competencyLevels.length : 0 }} up to two decimal places.
        </p>
        <div class="text-grey-dark-1 text-md mt-4 w-1/2 pr-4">
          <p>Competency Level</p>
          <div class="mt-1 flex flex-wrap">
            <p
              v-for="(item) in competencyLevels"
              :key="item.score"
              class="mr-3"
            >
              <span class="font-bold">{{ item.score }}.0</span>
              = {{ item.name }}
            </p>
          </div>
        </div>
        <TargetsTable :handler="handler" />
      </div>
    </div>
  </div>
</template>

<script>
import Button from "../../../components/Button/Button.vue";
import TargetsTable from "../components/TargetsTable";
import { mapActions, mapGetters, mapState } from "vuex";
import store from "@/store";
import arrowRight from "@/assets/img/icons/arrow-right-white.svg";
import arrowLeft from "@/assets/img/icons/arrow-right.svg";

export default {
  name: "Targets",
  components: { Button, TargetsTable },
  data: () => ({
    mappedList: null,
    arrowRight,
    arrowLeft
  }),
  computed: {
    ...mapState({
      title: (state) => state.assessmentSetup.assessmentTitle,
      id: (state) => state.assessmentSetup.cloneId,
      generalInfo: (state) => state.assessmentSetup.generalInfo,
      surveyCompetencyLevels: (state) => state.assessmentSetup.competencyLevels,
    }),
    ...mapGetters({
      isImpersonating: "auth/isImpersonating",
      isSeller: "auth/isSeller",
      checkChanges: "assessmentSetup/getDirtyTargets",
    }),
    isIndividual() {
      return this.generalInfo?.surveyType === 0;
    },
    isOrganizational() {
      return this.generalInfo?.surveyType === 1;
    },
    competencyLevels() {
      return this.surveyCompetencyLevels;
    },
    productVersion(){
      return this.generalInfo.productVersion;
    },
  },
  methods: {
    ...mapActions({
      updateTargets: "assessmentSetup/updateTargets",
      openModal: "modals/openModal",
      showError: "alerts/showError",
    }),
    async goNext() {
      await this.save();
      this.$router.push("/assessments/settings");
    },
    async save() {
      var list = JSON.parse(JSON.stringify(this.mappedList));
      for (let i = 0; i < list.length; i++) {
        for (let j = 0; j < list[i].targets.length; j++) {
          if (typeof list[i].targets[j].value == "string") {
            list[i].targets[j].value = parseFloat(list[i].targets[j].value);
          }
        }
      }
      try {
      await this.updateTargets({
        id: this.id,
        targets: list,
        surveyType: this.generalInfo?.surveyType,
      });
    } catch(err){
        this.showError({
          messageHeader: "Error",
          message: err.message || "Error updating targets. Please try again in a few moments",
        });
        throw err;
      }
    },
    goBack() {
      if (this.isIndividual) {
        this.$router.push("/assessments/job-roles");
      } else {
        this.$router.push("/assessments/participants");
      }
    },
    handler(val) {
      this.mappedList = val;
    },
    displayVersionForSeller(version) {
      if (version &&  version !== "" && (this.isImpersonating || this.isSeller)) 
        return " (v" + version + ")";
      return "";
    },
  },
  beforeRouteEnter(to, _, next) {
    const title = store.getters["assessmentSetup/getAssessmentTitle"];
    to.meta.sidenav.title = title + " Setup";
    next();
  },
  beforeRouteLeave(to, _, next) {
    if (to.path === "/assessments/settings") next();
    else {
      let values = this.mappedList.map((item) =>
        item.targets.map((i) => i.value)
      );
      if (this.checkChanges([...values]))
        this.openModal({
          modal: "exitAssessmentModal",
          props: { next: next, save: this.save, path: to.path },
        });
      else next();
    }
  },
};
</script>
