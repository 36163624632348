<template>
  <div :data-testid="`listitem-${index}`">
    <div class="flex items-center flex-row w-full py-2 pl-2">
      <div class="flex w-tableIndex justify-center mr-1 pr-3">
        <p class="font-normal text-md text-grey-dark-2">
          {{ index + 1 }}
        </p>
      </div>
      <div class="flex flex-grow max-w-1/4 items-center">
        <p class="text-md text-grey-dark-1 font-semibold">
          {{ source.competencyName }}
        </p>
      </div>
      <div class="w-30 mr-5" />
      <div
        v-for="(item, index) in targetsList"
        :key="item.targetId"
        class="flex w-150 justify-evenly items-center mr-5"
      >
        <div class="w-75 justify-center flex">
          <p class="text-md text-grey-dark-2">
            {{ parseFloat(item.originalValue).toFixed(2) }}
          </p>
        </div>
        <div class="w-75 justify-center flex">
          <input
            v-model="item.value"
            :disabled="disableAll && current[index]"
            @input="
              (ev) => {
                toggleDisabled(true);
                current[index] = false;
                extendOnChange(compIndex, item.jobRoleId, item.value, ev);
              }
            "
            class="focus:outline-none py-1 px-1.5 border rounded border-grey-light-1 font-semibold text-md text-grey-dark-1 w-targetInput text-center"
            :class="setColor(item.value, item.originalValue)"
            :data-testid="`input-${item.targetId}`"
          />
        </div>
      </div>
    </div>
    <div class="border border-grey-light-1 border-top-none" />
  </div>
</template>
<script>
import { mapState } from 'vuex';
import debounce from 'lodash/debounce';
export default {
  name: 'ListItem',
  props: {
    index: {
      type: Number,
    },
    source: { type: Object, default: () => ({}) },
    toggle: { type: Function, default: () => {} },
    onChange: { type: Function, default: null },
    jobRoles: { type: Array, default: [] },
    slicingIndex: { type: Number, default: 0 },
    disableAll: { type: Boolean },
    toggleDisabled: { type: Function },
  },
  data: () => ({ current: [true, true, true] }),
  computed: {
    ...mapState({
      generalInfo: (state) => state.assessmentSetup.generalInfo,
      maxCompetencyTarget: (state) => state.assessmentSetup.competencyLevels.length - 1,
    }),
    isIndividual() {
      return this.generalInfo?.surveyType === 0;
    },
    isOrganizational() {
      return this.generalInfo?.surveyType === 1;
    },
    compIndex() {
      return this.index;
    },
    targetsList() {
      if (this.isIndividual) return this.slicedTargets;
      if (this.isOrganizational) return this.source.targets;
      return [];
    },
    slicedTargets() {
      return this.source.targets
        .filter((el) => this.jobRoles.indexOf(el.jobRoleId) > -1)
        .slice(this.slicingIndex * 3, this.slicingIndex * 3 + 3);
    },
  },
  methods: {
    setColor(value, originalValue) {
      if (value > originalValue) return 'bg-green-3';
      else if (value < originalValue) return 'bg-red-2';
    },
    extendOnChange: debounce(function(compIndex, jobRoleId, value, ev) {
      let correctedValue = parseFloat(value);
      if (!correctedValue) {
        correctedValue = 0;
      } else if (correctedValue > this.maxCompetencyTarget) {
        correctedValue = this.maxCompetencyTarget;
      } else if (correctedValue < 0) {
        correctedValue = 0;
      } /* else if (!Number.isInteger(correctedValue / 0.5)) {
        correctedValue = Math.floor(correctedValue) + 0.5;
      } REMOVED FOR ITA 798*/
      this.onChange(compIndex, jobRoleId, correctedValue);
      this.current = [true, true, true];
      this.toggleDisabled(false);
    }, 1000),
    extendToggle() {
      this.toggle(this.source.index);
    },
  },
};
</script>
