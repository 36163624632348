var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"flex flex-grow justify-end mb-2"},[_c('Button',{staticClass:"mr-2",attrs:{"text":"Reset to Benchmarks","type":"secondary","size":"medium","disabled":_vm.disableReset,"onClick":_vm.resetBenchmarks,"data-testid":"benchmarks-button"}}),_c('Button',{attrs:{"text":"Save Progress","type":"secondary","size":"medium","onClick":_vm.saveProgress,"data-testid":"save-button"}})],1),_c('div',{staticClass:"flex flex-row w-full items-center bg-grey-light-3 py-1 px-2 rounded",class:_vm.mappedList.length < 10 ? '' : 'pr-2'},[_vm._m(0),_vm._m(1),(_vm.slicingIndex > 0)?_c('button',{staticClass:"bg-white h-btn w-30 rounded flex justify-center items-center mr-5",attrs:{"data-testid":"left-button"},on:{"click":_vm.decreaseSlice}},[_c('img',{staticClass:"transform rotate-180",attrs:{"src":_vm.arrow}})]):_c('div',{staticClass:"w-30 mr-5"}),(_vm.isOrganizational)?_c('div',{staticClass:"flex flex-col w-150 mr-5"},[_vm._m(2),_vm._m(3)]):_vm._e(),_vm._l((_vm.slicedColumns),function(item){return (_vm.isIndividual)?_c('div',{key:item.targetId,staticClass:"flex flex-col w-150 mr-5"},[_c('div',{staticClass:"flex flex-row items-center justify-center focus:outline-none"},[_c('p',{staticClass:"text-center text-sm-2 text-grey-light mr-0.4 min-h-32 flex items-center"},[_vm._v(" "+_vm._s(item.jobRoleName)+" ")])]),_vm._m(4,true)]):_vm._e()}),(_vm.slicingIndex * 3 < _vm.headerColumns.length - 3)?_c('button',{staticClass:"bg-white h-btn w-30 rounded flex justify-center items-center",attrs:{"data-testid":"right-button"},on:{"click":_vm.increaseSlice}},[_c('img',{attrs:{"src":_vm.arrow}})]):_vm._e()],2),_c('div',[_c('VirtualList',{staticStyle:{"max-height":"360px","overflow-y":"auto"},attrs:{"data-key":'competencyId',"data-sources":_vm.mappedList,"data-component":_vm.itemComponent,"extra-props":{
        toggle: _vm.toggleItem,
        onChange: _vm.onChange,
        slicingIndex: _vm.slicingIndex,
        jobRoles: this.jobRoles,
        disableAll: _vm.disableAll,
        toggleDisabled: _vm.toggleDisabled,
      },"data-testid":"targets-table"}})],1)])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"flex flex-row items-center focus:outline-none w-tableIndex mr-1"},[_c('p',{staticClass:"text-sm-2 text-grey-light mr-0.4"},[_vm._v("NO.")])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"flex flex-grow max-w-1/4"},[_c('div',{staticClass:"flex flex-row items-center focus:outline-none"},[_c('p',{staticClass:"text-sm-2 text-grey-light mr-0.4"},[_vm._v("COMPETENCY")])])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"flex flex-row items-center justify-center focus:outline-none"},[_c('p',{staticClass:"text-center text-sm-2 text-grey-light mr-0.4 min-h-32 flex items-center"},[_vm._v(" ORGANIZATION ")])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"flex mt-1.5"},[_c('p',{staticClass:"text-grey-dark-2 text-sm w-75 text-center"},[_vm._v(" Professional Benchmark ")]),_c('p',{staticClass:"text-grey-dark-2 text-sm w-75 text-center"},[_vm._v(" Company Target ")])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"flex mt-1.5"},[_c('p',{staticClass:"text-grey-dark-2 text-sm w-75 text-center"},[_vm._v(" Professional Benchmark ")]),_c('p',{staticClass:"text-grey-dark-2 text-sm w-75 text-center"},[_vm._v(" Company Target ")])])}]

export { render, staticRenderFns }